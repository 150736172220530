<template>
  <div>
    <div
      show
      variant="light"
      class="
        alert alert-custom alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-col md="4">
        <b-col md="12"><label>File đối soát của 9PAY :</label></b-col>
        <b-col md="6"
          ><b-button
            class="mr-3"
            pill
            variant="primary"
            @click="downloadReconcileFile('uploaded')"
            ><i class="fa fa-download"></i>Download</b-button
          ></b-col
        >
      </b-col>
      <b-col md="4">
        <b-col md="12"><label>File đối soát của VietinBank :</label></b-col>
        <b-col md="6"
          ><b-button
            class="mr-3"
            pill
            variant="primary"
            @click="downloadReconcileFile('disputed')"
            ><i class="fa fa-download"></i>Download</b-button
          ></b-col
        >
      </b-col>
      <b-col md="4">
        <b-col md="12"><label>File tổng hợp giao dịch sai lệch :</label></b-col>
        <b-col md="6"
          ><b-button
            class="mr-3"
            pill
            variant="primary"
            @click="downloadVietinDiffFile()"
            ><i class="fa fa-download"></i>Download</b-button
          ></b-col
        >
      </b-col>
    </div>

    <div
      show
      variant="light"
      class="
        alert alert-custom alert-white alert-shadow
        fade
        show
        gutter-b
        justify-content-end
      "
    >
      <b-col md="6">
        <b-col md="12"><label>Chạy lại file đối soát của 9Pay</label></b-col>
        <b-col md="6"
          ><b-button
            class="mr-3"
            pill
            variant="primary"
            @click="reUpload9PayReconcile()"
            ><i class="fa fa-play"></i>Run</b-button
          ></b-col
        >
      </b-col>
      <b-col md="6">
        <b-col md="12"
          ><label>Upload file xác nhận giao dịch sai lệch</label></b-col
        >
        <div class="input-group">
          <div class="custom-file" md="8">
            <input
              type="file"
              ref="file"
              id="customFileInput"
              class="custom-file-input"
              aria-describedby="customFileInput"
              v-on:change="handleFileUpload()"
            />
            <label class="custom-file-label" for="customFileInput">{{
              this.file && this.file.name ? this.file.name : "Select File"
            }}</label>
          </div>
          <b-col md="4"
            ><b-button class="mr-3" pill variant="primary" @click="submitFile()"
              ><i class="fa fa-upload"></i>Upload</b-button
            ></b-col
          >
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import ReconcileRepo from "@/core/repositories/reconcileRepository";
import Common from "@/core/mixins/common";
import Message from "@/core/config/message.config";

export default {
  name: "VietinbankReconcile",
  mixins: [Common],
  components: {},
  props: ["date", "bankCode"],
  data() {
    return {
      file: false
    };
  },
  methods: {
    downloadReconcileFile(type = "uploaded") {
      this.$bus.$emit("show-loading", true);
      ReconcileRepo.downloadRawFile(this.date, this.bankCode, type)
        .then(response => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code == 1) {
            return this.showError(
              response.data.message ?? Message.ERROR.DOWNLOAD_FILE
            );
          }
          const fileName =
            type == "uploaded"
              ? "9PayReconcileVietin-" + this.date + ".txt"
              : "VietinBankReconcile-" + this.date + ".txt";
          this.downloadTxtFile(fileName, response.data);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return this.showError(Message.ERROR.DOWNLOAD_FILE);
        });
    },

    downloadVietinDiffFile() {
      this.$bus.$emit("show-loading", true);
      ReconcileRepo.downloadDiffFile(this.date, this.bankCode)
        .then(response => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code == 1) {
            return this.showError(
              response.data.message ?? Message.ERROR.DOWNLOAD_FILE
            );
          }
          this.downloadCsvFile(
            "VietinBankReconcileDiff-" + this.date + ".csv",
            response.data
          );
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return this.showError(Message.ERROR.DOWNLOAD_FILE);
        });
    },

    reUpload9PayReconcile() {
      this.$bus.$emit("show-loading", true);
      ReconcileRepo.reUpload9payFile(this.date, this.bankCode)
        .then(response => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code == 1) {
            this.showError(Message.ERROR.SERVER);
            return;
          }

          this.showSuccess(Message.SUCCESS.RUN_DATA);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return this.showError(Message.ERROR.SERVER);
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    submitFile() {
      if (!this.file) {
        this.showError("Vui lòng chọn File để upload");
      }
      this.$bus.$emit("show-loading", true);

      let formData = new FormData();
      formData.append("file", this.file);
      ReconcileRepo.uploadFinalFile(formData)
        .then(response => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code == 1) {
            this.showError(Message.ERROR.SERVER);
            return;
          }

          this.showSuccess(Message.SUCCESS.RUN_DATA);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return this.showError(Message.ERROR.SERVER);
        });
    }
  },
  created() {}
};
</script>

<style scoped>
.custom-file {
  overflow: hidden;
}

.custom-file-input {
  white-space: nowrap;
}
</style>
