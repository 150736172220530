<template>
  <div>
    <div class="row mb-8">
      <div class="col-lg-3 mb-lg-3 mb-3">
        <label>Phân loại:</label>
        <select v-model="filter.type_provider" class="form-control">
          <option value="1">Ngân hàng liên kết</option>
          <option value="2">ĐVCƯDV</option>
        </select>
      </div>

      <div v-if="filter.type_provider == 1" class="col-lg-3 mb-lg-3mb-3">
        <label>Đối tác:</label>
        <select v-model="filter.provider" class="form-control">
          <option value="">Tất cả</option>
          <option
            v-for="item in provider_bank"
            v-bind:key="item.name"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <div v-if="filter.type_provider == 2" class="col-lg-3 mb-lg-3mb-3">
        <label>Đối tác:</label>
        <select v-model="filter.provider" class="form-control">
          <option value="">Tất cả</option>
          <option
            v-for="item in provider_other"
            v-bind:key="item.name"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-lg-6 mb-lg-6 mb-6">
        <label>Ngày đối soát:</label>
        <div class="input-daterange input-group" id="kt_datepicker">
          <input
            type="date"
            v-model="filter.date_from"
            class="form-control datatable-input"
            name="start"
            placeholder="Từ"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="la la-ellipsis-h"></i>
            </span>
          </div>
          <input
            v-if="filter.provider == 'SACOMBANK' || filter.provider == 'TCB'"
            type="date"
            v-model="filter.date_to"
            class="form-control datatable-input"
            name="end"
            placeholder="Đến"
          />
        </div>
      </div>
    </div>
    <div class="row mt-8">
      <div class="col-lg-12 text-right">
        <button
          class="btn btn-primary btn-primary--icon"
          @click="createLinkSearch()"
        >
          <span>
            <i class="la la-search"></i>
            <span>Tìm kiếm</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filterReconcile",
  data() {
    return {
      filter: {
        type_provider: 1,
        provider: "",
      },
      provider_bank: [
        { name: "Techcombank", value: "TCB" },
        { name: "Sacombank", value: "SACOMBANK" },
        { name: "Vietinbank", value: "CTG" },
        { name: "VPBank", value: "VPB" },
        { name: "Bản Việt", value: "VCCB" },
        { name: "MSB", value: "MSB" },
        { name: "Vietcombank", value: "VCB" },
      ],
      provider_other: [
        { name: "Payoo", value: "PAYOO" },
        { name: "Imedia", value: "IMEDIA" },
        { name: "Imedia (thẻ)", value: "IMEDIA_CARD" },
        { name: "Epay", value: "EPAY" },
        { name: "Epay (thẻ)", value: "EPAY_CARD" },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    createLinkSearch() {
      if (!this.filter.provider) {
        delete this.filter.provider;
      }

      if (!this.filter.date_from) {
        delete this.filter.date_from;
      }

      if (!this.filter.date_to) {
        delete this.filter.date_to;
      }

      this.$emit("handleFilter", this.filter);
    },
  },
};
</script>
