<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Danh sách giao dịch</h6></b-col>
                  <b-col class="text-right">
                    <a
                      href="/#/reconcile/reconciles/upload"
                      class="btn btn-success"
                    >
                      <i class="fa fa-plus"></i>
                      Upload File
                    </a>
                  </b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <filter-reconcile
                  @handleFilter="handleFilter"
                ></filter-reconcile>
              </div>
              <div v-if="listProviderByReconcile.includes(filter.provider)">
                <b-table
                  :fields="fields"
                  :items="items"
                  details-td-class="datatable-cell"
                  hover
                  table-class="datatable-table"
                  tbody-tr-class="datatable-row"
                  thead-class="datatable-head"
                  thead-tr-class="datatable-row"
                >
                  <template #cell(detail)="data">
                    <a :href="`${data.value}`" target="_blank">Chi tiết</a>
                  </template>
                  <template #cell(export)="data">
                    <button
                      class="text-success"
                      @click="exportFile(data.item.id)"
                    >
                      <!--                    <i class="la la-file-export"></i>-->
                      <span>Xuất file</span>
                    </button>
                  </template>
                </b-table>
                <div class="mt-3">
                  <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                  ></b-pagination-nav>
                </div>
              </div>

              <VietinbankReconcile
                v-if="filter.provider == 'CTG'"
                :date="filter.date_from"
                :bankCode="filter.provider"
              ></VietinbankReconcile>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import filterReconcile from "@/view/pages/reconciles/components/filterReconcile.vue";
import VietinbankReconcile from "@/view/components/reconcile/Vietinbank";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {
    AlertDialogue,
    ExportModal,
    filterReconcile,
    VietinbankReconcile,
  },
  data() {
    return {
      filter: {},
      modalId: "test-exxport",
      id: null,
      listProviderByReconcile: ["SACOMBANK", "TCB", "PAYOO", "IMEDIA", "IMEDIA_CARD", "EPAY", "EPAY_CARD"],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "partner",
          label: "Đối tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "reconcile_date",
          label: "Ngày đối soát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "file_name",
          label: "Tên file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "time_execute",
          label: "Thời gian đối soát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "partner_transaction",
          label: "Tổng số giao dịch trong file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_match",
          label: "Giao dịch khớp",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_not_match",
          label: "Giao dịch sai lệch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "detail",
          label: "Chi tiết",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "export",
          label: "Xuất file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giao dịch đối soát" },
    ]);
    this.filter = this.$route.query;

    this.getList();
  },
  methods: {
    exportFile(id) {
      this.id = id;
      this.$bvModal.show(this.modalId);
    },
    async exportData(message = null) {
      this.$bvModal.hide(this.modalId);
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      return CmsRepository.exportFileReconcileWallet({
        exportDesc: message,
        wallet_type: "RECONCILE_AUTO",
        id: this.id,
      })
        .then(async (response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            self.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }
          let link = window.location.origin + "/#/reconcile/export-data";

          self.$bus.$emit("show-loading", false);
          const cfm = await this.$refs.confirmDialogue.show({
            title: "Download File",
            message:
              "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" +
              link +
              "' target='_blank'>TẠI ĐÂY</a>",
            okButton: "Ok",
          });

          if (!cfm) {
            this.$bus.$emit("show-loading", false);
            return (self.errorMessage = "Có lỗi khi lấy thông tin");
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (self.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        if (!this.listProviderByReconcile.includes(params.provider)) {
          this.$bus.$emit("show-loading", false);
          return;
        }
        let response = await CmsRepository.listReconcile(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data;

        if (this.items.length) {
          this.items.forEach((value) => {
            value["detail"] = "#/reconcile/reconciles/detail?id=" + value.id;
          });
        }

        this.paginate.total = body.data.total;
        this.paginate.totalPage = body.data.last_page;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter(filter) {
      this.filter = filter;
      this.getList();
    },
  },
};
</script>
